.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.img-popup-overlay {
  position: absolute;
  z-index: 1301;
  background-color: rgb(0, 0, 0);
  opacity: 0.6;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.img-popup-close {
  position: absolute;
  right: -35px;
  background: rgb(255, 255, 255);
  z-index: 1302;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  font-size: 1.25rem;
  line-height: 35px;
  height: 35px;
  width: 35px;
  box-shadow: rgb(0 0 0) 0px 0px 10px -2px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  color: #000;
}

.img-popup-close:hover {
  background: #a0a0a0;
  color: #fff;
}

.img-popup-del {
  position: absolute;
  right: -15px;
  background: rgb(255, 35, 35);
  z-index: 1299;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  font-size: 0.75rem;
  line-height: 20px;
  height: 20px;
  width: 20px;
  box-shadow: rgb(0 0 0) 0px 0px 10px -2px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  color: rgb(255, 255, 255);
}

.img-popup-del:hover {
  background: rgb(255, 85, 85);
}

.btn-ok {
  background-color: #28a745 !important;
}

.btn-cancel {
  background-color: #5a6268 !important;
  color: #fff !important;
}

.btn-delete {
  background-color: #c82333 !important;
  color: #fff !important;
}

.btn-ok,
.btn-cancel,
.btn-delete {
  height: 45px;
  width: 125px;
  font-size: 1em !important;
}

.btn-ok:hover,
.btn-cancel:hover,
.btn-delete:hover {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.popup-label {
  font-family: arial;
  color: rgba(0, 0, 0, 0.54);
  display: block;
}

.popup-heading-accordeon {
  font-size: 1.5rem;
  font-weight: bold;
}

.popup-cards-wrapper {
  margin: 1em 0 1em 0;
  overflow-y: scroll;
  height: 300px;
  background-color: #E8E8E8;
  border-radius: 5px;
}

.popup-image-prev {
  max-width: 150px;
  margin: 10px 10px;
}
