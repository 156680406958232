@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.img-popup-overlay {
  position: absolute;
  z-index: 1301;
  background-color: rgb(0, 0, 0);
  opacity: 0.6;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.img-popup-close {
  position: absolute;
  right: -35px;
  background: rgb(255, 255, 255);
  z-index: 1302;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 50%;
  font-size: 1.25rem;
  line-height: 35px;
  height: 35px;
  width: 35px;
  box-shadow: rgb(0 0 0) 0px 0px 10px -2px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  color: #000;
}

.img-popup-close:hover {
  background: #a0a0a0;
  color: #fff;
}

.img-popup-del {
  position: absolute;
  right: -15px;
  background: rgb(255, 35, 35);
  z-index: 1299;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 50%;
  font-size: 0.75rem;
  line-height: 20px;
  height: 20px;
  width: 20px;
  box-shadow: rgb(0 0 0) 0px 0px 10px -2px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  color: rgb(255, 255, 255);
}

.img-popup-del:hover {
  background: rgb(255, 85, 85);
}

.btn-ok {
  background-color: #28a745 !important;
}

.btn-cancel {
  background-color: #5a6268 !important;
  color: #fff !important;
}

.btn-delete {
  background-color: #c82333 !important;
  color: #fff !important;
}

.btn-ok,
.btn-cancel,
.btn-delete {
  height: 45px;
  width: 125px;
  font-size: 1em !important;
}

.btn-ok:hover,
.btn-cancel:hover,
.btn-delete:hover {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.popup-label {
  font-family: arial;
  color: rgba(0, 0, 0, 0.54);
  display: block;
}

.popup-heading-accordeon {
  font-size: 1.5rem;
  font-weight: bold;
}

.popup-cards-wrapper {
  margin: 1em 0 1em 0;
  overflow-y: scroll;
  height: 300px;
  background-color: #E8E8E8;
  border-radius: 5px;
}

.popup-image-prev {
  max-width: 150px;
  margin: 10px 10px;
}

a {
  color: #3ca935;
}
body {
  font-family: "Lato", sans-serif;
}
.thin-wrapper {
  display: flex;
  max-width: 990px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  height: 100vh;
  padding: 0 15px;
}

.inner {
  display: flex;
  flex-direction: column;
  padding: 1em;
  box-shadow: 0 1.4px 10px rgba(0, 0, 0, 0.045), 0 11px 80px rgba(0, 0, 0, 0.09);
  border-radius: 25px;
}

.logo {
  display: inline-block;
  text-align: center;
}

.logo img {
  max-width: 150px;
}

.title {
  text-align: center;
  font-size: 24px;
  color: #e30815;
  margin-top: 1em;
}

.lost-paswd {
  font-size: 14px;
}

.form-login {
  max-width: 400px;
  margin: 0 auto;
}
.form-login table {
}

button {
}
button.full-width {
  width: 100%;
}
button.primary:hover {
  opacity: 0.8;
  background: #d3d3d3;
  transition: all 200ms;
}

button.primary {
  background: #e30815;
  color: #fff;
  margin-bottom: 1em;
}
button.outline {
  background: none;
  color: #e30815;
  border-color: #e30815;
}
button.outline:hover {
  background: #eee;
  border-color: #e30815;
}

.dash-card {
  background: #fff;
  box-shadow: 0 3.6px 7.9px rgba(0, 0, 0, 0.035),
    0 29px 63px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding: 4% 2% !important;
  color: #3ca935;
}
.dash-card span,
.dash-card svg {
  color: #e2e2e2;
  position: relative;
}
.dash-card span:before {
  content: "";
  width: 50px;
  height: 5px;
  position: absolute;
  background: #3ca935;
  border-radius: 100px;
  bottom: 0;
}

.dash-card:hover span:before {
  background: #fff;
}
.left-menu svg {
  color: #3ca935;
}
.left-menu a {
  color: #333;
}
.left-menu a:hover {
  background: #3ca935;
  color: #fff;
}
.left-menu a:hover svg {
  color: #fff;
}

.MuiPaper-elevation1 {
  box-shadow: 16px 11px 10px 0px rgb(223 214 214 / 20%),
    2px 2px 14px 8px rgb(236 236 236 / 14%), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.dash-card:hover {
  background: #3ca935;
  transition: all 200ms;
}
.dash-card:hover span {
  color: #fff !important;
}

.top-admin-bar {
  box-shadow: none !important;
  border-bottom: 1px solid #240a5117;
}
.top-admin-bar button svg path {
  color: #e30815;
}
.top-admin-bar h6 {
  font-weight: 300;
  color: #b1b1b1;
  font-size: 1rem;
}

.MuiPaper-elevation1 {
  box-shadow: none;
}

.top-admin-bar {
  background: #fff;
}
.spacer {
  flex: 1 1;
}

.t-jumbotron {
  background: #f7f7f7;
}
.t-jumbotron.blue {
  background: #f8fbff;
}

.big-text {
  font-size: 2em;
}

.sumup {
  background: #3ca935;
  font-size: 1.2em;

  color: #fff !important;
}
.sumup input {
  color: #fff !important;
  font-size: 1.2em;
}

.auto-w {
  width: auto;
}

.login-panel {
  min-width: 400px;
}

.login-panel .MuiPaper-elevation1 {
  box-shadow: none !important;
  width: 100%;
}

.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #000000;
}
.footer span {
  color: #ffffff;
  float: right;
  margin-right: 50px;
}
.footer a:hover {
  color: #e30815;
}

.MuiCircularProgress-svg {
  color: #e30815;
}

.HideErrors p{
  display: none;
}

/* .autocompleteinput input {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    box-sizing: inherit;
}

.autocompleteinput div {
    margin-top: -8px;
    margin-bottom: -8px;
} */

